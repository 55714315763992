import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'components/styles/withStyles'

export const styles = theme => ({
  root: {
    '& > div': {
      maxWidth: 960,
      margin: '0 auto',
      padding: `${theme.utils.spacing(2)}  20px ${theme.utils.spacing(7)} 20px`,

      [theme.breakpoints.up('md')]: {
        padding: `${theme.utils.spacing(5)} ${theme.utils.spacing(
          18,
        )} ${theme.utils.spacing(18)} ${theme.utils.spacing(12)}`,
      },

      '& > h1': {
        ...theme.typography.h1,
        margin: 0,
        marginBottom: theme.utils.spacing(5),
      },
      '& > h2': {
        ...theme.typography.articleH2,
        margin: 0,
        marginBottom: theme.utils.spacing(3),
      },
      '& > h3': {
        ...theme.typography.h3,
      },
      '& > p, & > ul li': {
        ...theme.typography.body1,
      },
      '& > p': {
        marginBottom: theme.utils.spacing(5),
      },
      '& > ul': {
        listStyle: 'none',
        marginBottom: theme.utils.spacing(5),
        marginTop: theme.utils.spacing(4),
        '& li': {
          position: 'relative',
          '& + li': {
            marginTop: theme.utils.spacing(4),
          },
          '&::before': {
            position: 'absolute',
            content: '""',
            height: 25,
            width: 25,
            top: 2,
            bottom: 0,
            left: theme.utils.spacing(-5),
            right: 0,
            backgroundImage: `url('data:image/svg+xml;utf8,<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" fill="%23A385FB" viewBox="0 0 24 24"><path d="M10 17c-.26 0-.51-.1-.71-.29l-4-4A.996.996 0 1 1 6.7 11.3l3.3 3.29 7.29-7.29a.996.996 0 1 1 1.41 1.41l-8 8c-.19.19-.44.29-.7.29z"/><path d="M12 24C5.38 24 0 18.62 0 12S5.38 0 12 0s12 5.38 12 12-5.38 12-12 12zm0-22C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2z"/></svg>')`,
          },
        },
      },

      '& > ol': {
        listStyle: 'none',
        marginBottom: theme.utils.spacing(5),
        ...theme.typography.body1,
        '& li': {
          counterIncrement: 'li',
          position: 'relative',
          '& + li': {
            marginTop: theme.utils.spacing(4),
          },
        },
        '& li::before': {
          content: 'counter(li) "."',
          position: 'absolute',
          left: -35,
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },

      '& .wp-block-image': {
        position: 'relative',
        margin: `${theme.utils.spacing(7)} 0`,
        '& img': {
          width: '100%',
          height: '100%',
        },
      },
      '& .wp-block-image figcaption': {
        ...theme.typography.body2,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 70,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.utils.spacing(4),
        background: 'rgba(255, 255, 255, 0.8)',
        textTransform: 'uppercase',
        fontWeight: theme.typography.fontWeightRegular,
      },
      '& .wp-block-embed-youtube': {
        margin: 0,
      },

      '& .wp-block-embed__wrapper': {
        position: 'relative',
        paddingBottom: '56.25%',
        paddingTop: 30,
        height: 0,
        overflow: 'hidden',
        margin: '56px 0',
        '& iframe': {
          position: 'absolute',
          top: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
        },
      },
    },
  },
})

const Content = props => {
  const { children, classes, className, ...other } = props

  return (
    <div className={classnames(classes.root, className)} {...other}>
      {children}
    </div>
  )
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

Content.uiName = 'Content'

export default withStyles(styles)(Content)
