import React from 'react'
import PropTypes from 'prop-types'
import Content from 'components/Content'

const Wysiwyg = React.forwardRef((props, ref) => {
  const { children, ...other } = props

  return (
    <Content ref={ref} {...other}>
      {children}
    </Content>
  )
})

Wysiwyg.propTypes = {
  children: PropTypes.node.isRequired,
}

Wysiwyg.uiName = 'Block/Wysiwyg'

export default Wysiwyg
