import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'components/styles/withStyles'

export const styles = theme => {
  return {
    '@keyframes gradientAnimation': {
      '0%': { backgroundPosition: '0% 24%' },
      '50%': { backgroundPosition: '100% 77%' },
      '100%': { backgroundPosition: '0% 24%' },
    },
    root: {
      position: 'relative',
      background: theme.palette.tertiary,
      padding: `${theme.utils.spacing(8)} ${theme.utils.spacing(
        3,
      )} ${theme.utils.spacing(7)} ${theme.utils.spacing(6)}`,
      [theme.breakpoints.up('md')]: {
        maxWidth: 700,
        padding: `${theme.utils.spacing(8)} ${theme.utils.spacing(
          17,
        )} ${theme.utils.spacing(9)} ${theme.utils.spacing(6)}`,
      },
      '&::before': {
        content: '""',
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: `linear-gradient(112deg, ${
          theme.palette.accent.turquoise
        }, ${theme.palette.accent.purple}, ${theme.palette.accent.pink}, ${
          theme.palette.accent.green
        })`,
        animation: `gradientAnimation 5s ease infinite`,
        backgroundSize: '300% 300%',
        left: -20,
        top: 20,
        zIndex: -1,
      },
    },
    main: {
      background: theme.palette.blue[200],
    },
  }
}

const FancyTile = props => {
  const { classes, className: classNameProp, color, children, ...other } = props

  const className = classnames(
    classes.root,
    {
      [classes.main]: color === 'main',
    },
    classNameProp,
  )

  return (
    <div className={className} {...other}>
      {children}
    </div>
  )
}

FancyTile.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['light', 'main']),
}
FancyTile.defaultProps = {
  className: '',
  color: 'main',
}

FancyTile.uiName = 'FancyTile'

export default withStyles(styles)(FancyTile)
