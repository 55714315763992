import React from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'
import ErrorPage from 'next/error'
import AppContext from 'containers/AppContext'

const Blocks = ({ page, error, renderBlock, ...other }) => {
  const context = React.useContext(AppContext)
  const tagline =
    (context && context.settings && context.settings.tagline) || ''

  if (error && error.code) {
    return <ErrorPage statusCode={error.code} />
  }

  return (
    <div>
      <Head>
        <title>{page && page.title}</title>
        <meta name="description" content={tagline} />
      </Head>

      <div>
        {page.blocks.map((block, index) =>
          renderBlock(block, `block-${page.id}-${block.component}-${index}`),
        )}
      </div>
    </div>
  )
}

Blocks.propTypes = {
  error: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  renderBlock: PropTypes.func.isRequired,
}

Blocks.uiName = 'Blocks'

export default Blocks
