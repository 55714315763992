import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'components/styles/withStyles'
import Typography from 'components/Typography'
import Tile from 'components/Tile'
import Button from 'components/Button'
import Link from 'components/Link'

export const styles = theme => ({
  root: {
    maxWidth: 1440,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
    background: theme.palette.white,
    marginBottom: theme.utils.spacing(7),
    '& > *': {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
      marginBottom: theme.utils.spacing(15),
      flexDirection: 'row',
      '& > *': {
        width: '50%',
      },
    },
  },
  left: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
    },
  },
  image: {
    maxHeight: 700,
    objectFit: 'cover',
  },
  tile: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
})

const ContentBlock = props => {
  const {
    text,
    classes,
    className,
    image,
    align,
    color,
    title,
    subtitle,
    ctaText,
    ctaHref,
    ...other
  } = props

  return (
    <div
      className={classnames(classes.root, classes[align], className)}
      {...other}
    >
      {image && image.desktop && (
        <img className={classes.image} src={image.desktop} />
      )}
      <Tile color={color} className={classes.tile}>
        <Typography variant="h2" style={{ marginBottom: 31 }}>
          {title}
        </Typography>
        <Typography variant="h3" style={{ marginBottom: 15 }}>
          {subtitle}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: 30 }}>
          {text}
        </Typography>

        {ctaText && (
          <Button component={Link} href={ctaHref}>
            {ctaText}
          </Button>
        )}
      </Tile>
    </div>
  )
}

ContentBlock.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.object.isRequired,
  align: PropTypes.oneOf(['left', 'right']),
  className: PropTypes.string,
  image: PropTypes.object,
  color: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  ctaText: PropTypes.string,
  ctaHref: PropTypes.string,
}
ContentBlock.defaultProps = {
  align: 'left',
  text: '',
  title: '',
  subtitle: '',
  ctaText: '',
  ctaHref: '',
  image: null,
}

ContentBlock.uiName = 'ContentBlock'

export default withStyles(styles)(ContentBlock)
