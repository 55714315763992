import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'components/styles/withStyles'
import Card from 'components/Card'
import Button from 'components/Button'
import Typography from 'components/Typography'
import Link from 'components/Link'

export const styles = theme => ({
  root: {
    // maxWidth: 500,
  },
  container: {
    padding: theme.utils.spacing(4),
    '& > * + *': {
      marginTop: theme.utils.spacing(4),
    },
  },
  image: {
    display: 'block',
    width: '100%',
    height: 230,
    objectFit: 'cover',
    objectPosition: 'center',
  },
  center: {
    objectPosition: 'center',
  },
  top: {
    objectPosition: 'top',
  },
  bottom: {
    objectPosition: 'bottom',
  },
  title: {
    fontSize: '1.6rem',
    lineHeight: 1.8,
  },
  button: {
    width: 'calc(100% + 6px)',
    maxWidth: 'calc(100% + 6px)',
    marginTop: -3,
    marginLeft: -3,
    paddingLeft: theme.spacing.unit * 4 + 3,
  },
})

const ArticleCard = props => {
  const {
    classes,
    className: classNameProp,
    image,
    cta,
    linkType,
    imagePosition,
    title,
    link,
    secondaryTitle,
    ...other
  } = props

  const imageClassName = classnames(classes.image, {
    [classes.center]: imagePosition === 'center',
    [classes.top]: imagePosition === 'top',
    [classes.bottom]: imagePosition === 'bottom',
  })

  const className = classnames(
    classes.root,
    {
      [classes.noImage]: !image,
    },
    classNameProp,
  )

  return (
    <Card className={className} {...other}>
      {image && <img className={imageClassName} src={image} />}

      <Button className={classes.button} href={link} component={Link}>
        {cta}
      </Button>

      <div className={classes.container}>
        <Typography variant="body1" component="h2" className={classes.title}>
          <Link href={link} enableUnderline>
            {title}
          </Link>
        </Typography>

        {secondaryTitle && (
          <Typography variant="h3" color="secondary">
            {secondaryTitle}
          </Typography>
        )}
      </div>
    </Card>
  )
}

ArticleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  image: PropTypes.string,
  imagePosition: PropTypes.oneOf(['center', 'top', 'bottom']),
  cta: PropTypes.string,
  link: PropTypes.string,
  linkType: PropTypes.string,
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
}

ArticleCard.uiName = 'ArticleCard'

export default withStyles(styles)(ArticleCard)
