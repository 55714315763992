import { compose, setStatic } from 'recompose'
import WPClient from 'api/wordpress'
import ContactCardBlock from './ContactCardBlock'

export default compose(
  setStatic('getInitialProps', async block => {
    const { cards } = block.props

    const wpClient = new WPClient()
    const results = await wpClient.getPostsById(cards.join(','), 'contact')
    const parsedCards = results.map(card => ({
      ...card.cardData,
    }))

    return {
      ...block,
      props: {
        ...block.props,
        cards: parsedCards,
      },
    }
  }),
)(ContactCardBlock)
