import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Typography from 'components/Typography'
import ContactCard from 'components/ContactCard'
import withStyles from 'components/styles/withStyles'

export const styles = theme => ({
  root: {
    maxWidth: 1440,
    margin: '0 auto',
    padding: '0 20px',
    marginBottom: theme.utils.spacing(7),
    [theme.breakpoints.up('md')]: {
      padding: `0 ${theme.utils.spacing(12)}`,
      marginBottom: theme.utils.spacing(15),
    },
  },
  title: {
    marginBottom: theme.utils.spacing(6),
    fontSize: theme.typography.pxToRem(36),
    lineHeight: 1.27,
  },
  subtitle: {
    marginBottom: theme.utils.spacing(8),
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: theme.utils.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      flexDirection: 'row',
      '& > div': {
        paddingLeft: theme.utils.spacing(4),
        paddingRight: theme.utils.spacing(4),
      },
    },
  },
})

const ContactCardBlock = props => {
  const { cards, classes, className, title, subtitle, ...other } = props

  return (
    <div className={classnames(classes.root, className)} {...other}>
      <Typography className={classes.title} variant="h2">
        {title}
      </Typography>
      <Typography className={classes.subtitle} variant="h3">
        {subtitle}
      </Typography>
      <div className={classes.cardWrapper}>
        {cards.map((cardData, index) => (
          <div key={`contact-${index}`}>
            <ContactCard {...cardData} />
          </div>
        ))}
      </div>
    </div>
  )
}

ContactCardBlock.propTypes = {
  cards: PropTypes.array,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}

ContactCardBlock.defaultProps = {
  cards: [],
}

ContactCardBlock.uiName = 'ContactCardBlock'

export default withStyles(styles)(ContactCardBlock)
