import React from 'react'
import { compose, setStatic, withProps } from 'recompose'
import HeroBlock from 'blocks/HeroBlock'
import ContactCardBlock from 'blocks/ContactCardBlock'
import ContentBlock from 'blocks/ContentBlock'
import ArticlesBlock from 'blocks/ArticleCardBlock'
import Wysiwyg from 'blocks/Wysiwyg'
import Blocks from './Blocks'

export const BLOCK_MAPPER = {
  HeroBlock,
  ContactCardBlock,
  ContentBlock,
  ArticlesBlock,
  Wysiwyg,
}

export const renderBlock = (block, key) => {
  const { component, props, children } = block

  const Block = BLOCK_MAPPER[component]

  if (!Block) {
    return React.createElement('div', {}, `Block not found: ${component}`)
  }

  const blockChildren =
    typeof children === 'object' ? (
      children.map((_block, index) =>
        renderBlock(_block, `childblock-${index}`),
      )
    ) : (
      <div dangerouslySetInnerHTML={{ __html: children }} />
    )

  return <Block {...props} key={key} children={blockChildren} />
}

/*
 * The block parser maps Gutenberg blocks to functions if the block requires additional data that isn't provided by WP.
 * For example, matching a category slug with the categories in the app data and fetching products for that category
 * Each block can have a static method 'getInitialProps' which get's called if it's available
 */
export const parseBlock = ctx => async block => {
  let blockProps = {}
  if (
    BLOCK_MAPPER[block.component] &&
    BLOCK_MAPPER[block.component].getInitialProps
  ) {
    blockProps = await BLOCK_MAPPER[block.component].getInitialProps(block, ctx)
  }

  if (typeof block.children === 'object' && block.children.length > 0) {
    block.children = await Promise.all(block.children.map(parseBlock(ctx)))
  }

  return {
    ...block,
    ...blockProps,
  }
}

function BlockError(code, message) {
  this.message = message
  this.code = code
}

export const getInitialProps = (method = 'getPage') => async (
  ctx,
  { wpClient },
) => {
  const { query, asPath } = ctx

  let page = {
    title: '',
    blocks: [],
  }
  let error = {}

  /* WordPress expects the actual page's slug as the argument.
   * Extract the last part and send it as the slug argument
   * Make sure to handle trailing slashes
   */
  const fullPath = query && query.slug ? query.slug : asPath
  const pathPars = fullPath.endsWith('/') ? fullPath.slice(0, -1) : fullPath
  const slug = pathPars.split('/').pop()

  try {
    // const wpClient = globalWPClient || new WPClient()
    const results = await wpClient[method](slug)
    const wpPage = results[0]

    if (!wpPage) {
      throw new BlockError(404, 'No Page found')
    }

    const blocks = await Promise.all(wpPage.blocks.map(parseBlock({ slug })))

    page = {
      id: wpPage.id,
      slug: wpPage.slug,
      title: wpPage.title.rendered,
      meta: wpPage.meta,
      blocks,
      wpPage,
    }
  } catch (err) {
    error = err
  }

  return { page, error }
}

export const withBlocks = method =>
  compose(
    setStatic('getInitialProps', getInitialProps(method)),
    withProps(() => ({ renderBlock })),
  )

export default withBlocks()(Blocks)
