import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'components/styles/withStyles'
import ContentBackground from 'components/ContentBackground'
import FancyTile from 'components/FancyTile'
import Typography from 'components/Typography'
import Link from 'components/Link'
import Button from 'components/Button'

export const styles = theme => ({
  root: {
    marginBottom: theme.utils.spacing(7),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.utils.spacing(10),
    },
  },
})

const HeroBlock = props => {
  const {
    title,
    text,
    background,
    ctaText,
    ctaHref,
    classes,
    className,
    subtitle,
    ...other
  } = props

  return (
    <ContentBackground
      bgImage={background && background.desktop}
      className={classnames(classes.root, className)}
      {...other}
    >
      <FancyTile>
        <Typography variant="h1" style={{ marginBottom: 31 }}>
          {title}
        </Typography>
        <Typography variant="h3" style={{ marginBottom: 15 }}>
          {subtitle}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: 30 }}>
          {text}
        </Typography>

        {ctaText && (
          <Button component={Link} href={ctaHref}>
            {ctaText}
          </Button>
        )}
      </FancyTile>
    </ContentBackground>
  )
}

HeroBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  background: PropTypes.object,
  ctaText: PropTypes.string,
  ctaHref: PropTypes.string,
  subtitle: PropTypes.string,
}

HeroBlock.defaultProps = {
  title: '',
  text: '',
  subtitle: '',
  background: null,
  ctaText: '',
  ctaHref: '',
}

HeroBlock.uiName = 'HeroBlock'

export default withStyles(styles)(HeroBlock)
