import { compose, setStatic } from 'recompose'
import WPClient from 'api/wordpress'
import ArticleCardBlock from './ArticleCardBlock'

export default compose(
  setStatic('getInitialProps', async block => {
    const { articles } = block.props

    const wpClient = new WPClient()
    const results = await wpClient.getPostsById(articles.join(','))
    const parsedArticles = results.map(article => ({
      ...article.articleData,
    }))

    return {
      ...block,
      props: {
        ...block.props,
        articles: parsedArticles,
      },
    }
  }),
)(ArticleCardBlock)
