import React, { useContext } from 'react'
import { compose, setStatic, withProps, fromRenderProps } from 'recompose'
import { renderBlock, parseBlock } from '../blocks'
import Blocks from '../blocks/Blocks'
import AppContext from 'containers/AppContext'

export default compose(
  setStatic('getInitialProps', async (ctx, { appData, wpClient }) => {
    let page = {
      title: '',
      blocks: [],
    }
    let error = {}
    try {
      let frontpage = appData.frontpage

      if (!frontpage) {
        const site = await wpClient.getSite()
        frontpage = site.frontpage
      }

      const blocks = await Promise.all(frontpage.blocks.map(parseBlock()))

      page = {
        id: frontpage.id,
        slug: frontpage.post_name,
        title: frontpage.post_title,
        meta: {},
        blocks,
        wpPage: frontpage,
      }
    } catch (err) {
      error = err
      console.log('err', err)
    }

    return { page, error }
  }),
  withProps(() => ({
    renderBlock,
  })),
)(Blocks)
