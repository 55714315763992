import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'components/styles/withStyles'

export const styles = theme => ({
  root: {
    background: theme.palette.tertiary,
    padding: `${theme.utils.spacing(8)} 20px ${theme.utils.spacing(12)}`,
    [theme.breakpoints.up('md')]: {
      maxHeight: 600,
      padding: `${theme.utils.spacing(11)} ${theme.utils.spacing(
        17,
      )} ${theme.utils.spacing(16)} ${theme.utils.spacing(12)}`,
    },
  },
  dark: {
    background: theme.palette.blue[200],
  },
})

const Tile = props => {
  const { classes, className: classNameProp, color, children, ...other } = props
  const className = classnames(
    classes.root,
    {
      [classes.dark]: color === 'dark',
    },
    classNameProp,
  )

  return (
    <div className={className} {...other}>
      {children}
    </div>
  )
}

Tile.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Tile.uiName = 'Tile'

export default withStyles(styles)(Tile)
