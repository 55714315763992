import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'components/styles/withStyles'
import ArticleCard from 'components/ArticleCard'

export const styles = theme => ({
  root: {
    maxWidth: 1440,
    margin: '0 auto',
    padding: '0 20px',
    marginBottom: theme.utils.spacing(7),
    [theme.breakpoints.up('md')]: {
      padding: `0 ${theme.utils.spacing(12)}`,
      marginBottom: theme.utils.spacing(15),
    },
  },
  titleWrapper: {
    position: 'relative',
    textAlign: 'center',
    marginBottom: theme.utils.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.utils.spacing(15),
    },
  },
  title: {
    ...theme.typography.h3,
    display: 'inline-block',
    height: 44,
    margin: '0 auto',
    padding: '10px 30px',
    border: `2px solid ${theme.palette.primary.main}`,
    background: theme.palette.white,
    color: theme.palette.black,
    textTransform: 'uppercase',
  },
  line: {
    height: 2,
    position: 'absolute',
    top: '50%',
    bottom: '50%',
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
    background: theme.palette.primary.main,
    zIndex: -1,
  },
  articleWrapper: {
    maxWidth: 1130,
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: theme.utils.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: '0 5%',
      '& > div': {
        width: '50%',
        paddingLeft: theme.utils.spacing(4),
        paddingRight: theme.utils.spacing(4),
      },
      '& > *': {
        marginBottom: theme.utils.spacing(8),
      },
    },
  },
})

const ArticlCardBlock = props => {
  const { articles, title, classes, className, ...other } = props

  return (
    <div className={classnames(classes.root, className)} {...other}>
      <div className={classes.titleWrapper}>
        <div className={classes.title}>{title}</div>
        <span className={classes.line} />
      </div>
      <div className={classes.articleWrapper}>
        {articles &&
          articles.map((article, index) => (
            <div key={`article-${index}`}>
              <ArticleCard {...article} />
            </div>
          ))}
      </div>
    </div>
  )
}

ArticlCardBlock.propTypes = {
  articles: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

ArticlCardBlock.uiName = 'ArticlCardBlock'

export default withStyles(styles)(ArticlCardBlock)
