import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from '../styles/withStyles'

export const styles = theme => ({
  root: {
    width: '100%',
    height: 'auto',
    position: 'relative',
    padding: `${theme.utils.spacing(7)} 10px ${theme.utils.spacing(7)} 30px`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.utils.spacing(13)} ${theme.utils.spacing(
        3,
      )} ${theme.utils.spacing(11)} ${theme.utils.spacing(12)}`,
    },
  },
  bgImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    position: 'absolute',
    zIndex: -2,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    '$top &': {
      objectPosition: 'top',
    },
    '$bottom &': {
      objectPosition: 'bottom',
    },
  },
  top: {},
  bottom: {},
  container: {
    display: 'flex',
    maxWidth: 1440,
    margin: '0 auto',
  },
  right: {
    justifyContent: 'flex-end',
  },
})

const ContentBackground = props => {
  const {
    classes,
    className: classNameProp,
    bgImage,
    bgPosition,
    children,
    align,
    ...other
  } = props

  const className = classnames(
    classes.root,
    {
      [classes.top]: bgPosition === 'top',
      [classes.bottom]: bgPosition === 'bottom',
    },
    classNameProp,
  )

  return (
    <div className={classnames(classes.root, className)} {...other}>
      <img className={classes.bgImage} src={bgImage} />
      <div className={classnames(classes.container, classes[align])}>
        {children}
      </div>
    </div>
  )
}

ContentBackground.propTypes = {
  bgImage: PropTypes.string,
  bgPosition: PropTypes.oneOf(['center', 'top', 'bottom']),
  align: PropTypes.oneOf(['left', 'right']),
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

ContentBackground.defaultProps = {
  bgImage: '',
  className: '',
  align: 'left',
  bgPosition: 'center',
}

ContentBackground.uiName = 'ContentBackground'

export default withStyles(styles)(ContentBackground)
